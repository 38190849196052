<template>
  <v-dialog v-model="dialogVisible" persistent max-width="500" outlined>
    <v-card outlined rounded>
      <!-- Icon and Title -->
      <v-card-title class="justify-center">
        <v-avatar class="ma-3" size="100" tile>
          <v-img :src="require('@/assets/terms.png')"></v-img>
        </v-avatar>
      </v-card-title>
      <v-card-title class="justify-center">
        We have updated the <strong>Terms of Service Agreement</strong>
      </v-card-title>
      
      <!-- Terms of Service Checkbox with Activator -->
      <v-dialog persistent v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-flex d-flex justify-center align-center>
            <v-checkbox v-model="checkbox" readonly v-on="on" :tabindex="5">
              <template v-slot:label>
                <div>
                  I have read and agree to the
                  <a href="#" class="text-decoration-none cyan--text"><strong>Terms of Service Agreement</strong></a>
                </div>
              </template>
            </v-checkbox>
          </v-flex>
        </template>

        <!-- Inner Dialog Content -->
        <v-card>
          <v-card-title class="text-h5">
            Terms of Service Agreement
          </v-card-title>
          <hr/>
          <v-card-text class="text-justify">
            <v-card-text class="text-justify" v-html="activeTermsContent"></v-card-text>
          </v-card-text>
          <v-divider />
          <v-card-actions>
              <v-spacer></v-spacer>
            <v-btn :disabled="checkbox" color="primary" small outlined @click="dialog = false">Disagree</v-btn>
            <v-btn color="primary" small @click="agreeTerms()">Agree</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Done Button -->
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!checkbox" small color="primary" rounded class="ma-3" @click=saveUserTerm()>
          Done
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
  import { termsCollection, usersCollection } from "../../firebaseDb";
  import { mapState } from "vuex";
  
  export default {
    data() {
      return {
        dialog: false,
        dialogVisible: false,
        loading: true,
        activeTermsContent: "",
        checkbox: false,
        currentDocId: "",
      };
    },
    computed: {
        ...mapState(["currentUser"]),
    },
    created() {
      this.fetchActiveTerms();
    },
    methods: {
      agreeTerms() {
          this.dialog = false;
          this.checkbox = true;
      },

      saveUserTerm() {
        this.dialogVisible = false;
        // Ensure that the user's ID and the current document ID are available
        const userId = this.currentUser?.uid;
        const termDocId = this.currentDocId;

        if (!userId || !termDocId) {
          console.error("User ID or Term Document ID is missing");
          return;
        }
        const userRef = usersCollection.doc(userId);
        const docTermRef = `terms/${termDocId}`;

        userRef.get().then((docSnapshot) => {
            if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            const termsArray = userData.termsArray || [];
            // Check if the term reference already exists in the array to prevent duplicates
            if (!termsArray.includes(docTermRef)) {
              userRef.update({
                termsArray: [...termsArray, docTermRef],
              })
              .then(() => {
                console.log("Term reference added to user's termsArray");
              })
              .catch((error) => {
                console.error("Error updating user document: ", error);
              });
            } else {
              console.log("Term reference already exists in user's termsArray");
            }
            } else {
            // If the user document doesn't exist, create it with the terms array
              userRef.set({
                  termsArray: [docTermRef],
              })
              .then(() => {
                  console.log("User document created with the term reference");
              })
              .catch((error) => {
                  console.error("Error creating user document: ", error);
              });
            }
        }).catch((error) => {
            console.error("Error fetching user document: ", error);
        });
      },

      checkIfTermsAccepted() {
        const userId = this.currentUser?.uid;
        const termDocId = this.currentDocId;

        if (!userId || !termDocId) {
          console.error("User ID or Term Document ID is missing");
          return;
        }
        const userRef = usersCollection.doc(userId);
        userRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            const termsArray = userData.termsArray || [];
            // Check if the current term document ID is already in the user's termsArray
            if (termsArray.includes(`terms/${termDocId}`)) {
              // console.log("User has already accepted the current terms. Dialog will not be shown.");
              this.dialogVisible = false;
            } else {
              // console.log("User has not accepted the current terms. Showing dialog.");
              this.dialogVisible = true; 
            }
          } else {
            // console.log("User document does not exist. Showing dialog.");
            this.dialogVisible = true; 
          }
        }).catch((error) => {
          console.error("Error checking user document: ", error);
          this.dialogVisible = true; 
        });
      },

      fetchActiveTerms() {
        this.loading = true;
        termsCollection
          .where("isActive", "==", true)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              const activeTermDoc = querySnapshot.docs[0]; 
              this.currentDocId = activeTermDoc.id;  
              const activeTerm = activeTermDoc.data();

              this.activeTermsContent = activeTerm.content;

              const effectiveDate = activeTerm.effectiveDate; 
              const currentDate = new Date().toISOString().split('T')[0]; 

              if (effectiveDate <= currentDate) {
                this.checkIfTermsAccepted();
              } else {
                this.dialogVisible = false;
              }
            } else {
              this.activeTermsContent = "No active terms found.";
            }
          })
          .catch((error) => {
            console.error("Error fetching active terms:", error);
            this.activeTermsContent = "Error fetching active terms.";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  };
</script>
  
<style scoped>
  .headline {
    font-weight: bold;
  }
  .text-decoration-none a {
    text-decoration: none;
  }
</style>
  
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col xs="12" sm="12" md="12" lg="10" xl="8">
        <!-- Online Mode -->
        <ais-instant-search v-if="isOnline" :search-client="searchClient" index-name="cases_prod">
          <ais-autocomplete>
            <template slot-scope="{ indices, refine }">
              <v-card class="rounded-xxl" :style="borderStyle">
                <v-text-field
                  v-model="currentRefinement"
                  label="Search Cases"
                  solo
                  rounded
                  flat
                  hide-details
                  @input="refine"
                  @focus="showAutoComplete = true"
                  v-click-outside="handleCloseSearch"
                  @keydown.enter="handleEnterKey(currentRefinement)"
                  prepend-inner-icon="search"
                  clearable
                  color="darkTheme"
                />
                <v-expand-transition>
                  <div v-show="showAutoComplete">
                    <v-card>
                      <v-list v-if="currentRefinement" :class="['custom-list', darkTheme ? 'dark-list' : 'light-list']">
                        <v-list-item-group v-for="index in indices" :key="index.indexName">
                          <v-list-item v-for="hit in filteredHits(index.hits).slice(0, 8)" :key="hit.objectID">
                            <v-list-item
                              v-for="ref in hit.references"
                              :key="ref.refno"
                              :to="{ name: 'case', params: { id: hit.objectID } }"
                              @change="currentRefinement = ''"
                              class="mt-sm-2"
                            >
                              <v-list-item-icon class="ml-3">
                                <v-img
                                  v-if="faviconImage || lightImage"
                                  lazy-src="@/assets/placeholder.png"
                                  :width="30"
                                  aspect-ratio="1"
                                  alt="Protected"
                                  :src="logoImage"
                                ></v-img>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ ref.refno }}</v-list-item-title>
                                <v-list-item-subtitle class="text--primary">
                                  {{ ref.short }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="hit.idx">
                                  {{ hit.idx }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text class="mr-3 d-none d-sm-flex">
                                {{ showDate(hit.date) }}
                              </v-list-item-action-text>
                            </v-list-item>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list-item v-if="currentRefinement && !indices.some((index) => index.hits.length)">
                        <v-list-item-content class="text-center">
                          <v-list-item-title><em>Case "{{ currentRefinement }}" not found</em></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-expand-transition>
              </v-card>
            </template>
          </ais-autocomplete>
        </ais-instant-search>

        <!-- Offline Mode -->
        <v-card v-else class="rounded-xxl" :style="borderStyle">
          <v-text-field
            v-model="searchQuery"
            label="Search Cases"
            solo
            rounded
            flat
            hide-details
            prepend-inner-icon="search"
            clearable
            color="darkTheme"
            @input="handleOfflineSearch"
            @keydown.enter="handleOfflineSearch"
          />
          <v-expand-transition>
            <div v-show="showAutoComplete">
              <v-card>
                <v-list v-if="searchQuery" :class="['custom-list', darkTheme ? 'dark-list' : 'light-list']">
                  <v-list-item-group>
                    <v-list-item v-for="hit in filteredOfflineHits.slice(0, 8)" :key="hit.objectID">
                      <v-list-item
                        v-for="ref in hit.references"
                        :key="ref.refno"
                        :to="{ name: 'case', params: { id: hit.objectID } }"
                        class="mt-sm-2"
                      >
                        <v-list-item-icon class="ml-3">
                          <v-img
                            v-if="faviconImage || lightImage"
                            lazy-src="@/assets/placeholder.png"
                            :width="30"
                            aspect-ratio="1"
                            alt="Protected"
                            :src="logoImage"
                          ></v-img>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ ref.refno }}</v-list-item-title>
                          <v-list-item-subtitle class="text--primary">
                            {{ ref.short }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="hit.idx">
                            {{ hit.idx }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text class="mr-3 d-none d-sm-flex">
                          {{ showDate(hit.date) }}
                        </v-list-item-action-text>
                      </v-list-item>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-list-item v-if="searchQuery && !filteredOfflineHits.length">
                  <v-list-item-content class="text-center">
                    <v-list-item-title><em>Case "{{ searchQuery }}" not found</em></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import Fuse from "fuse.js";
import useLocalStorage from "@/composables/useLocalStorage";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      searchQuery: "",
      isOnline: navigator.onLine,
      currentRefinement: "",
      showAutoComplete: false,
      searchClient: algoliasearch(
        "4Z44T2TZTE",
        "afd9d28e0bc4c31b52e8dc5709077153"
      ),
      faviconImage: null,
      lightImage: null,
      selectedTier: "",
    };
  },

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  computed: {
    ...mapState(['offlineHits', 'searchValue', "userProfile"]),
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    borderStyle() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes.light.secondary}`,
      };
    },
    filteredOfflineHits() {
      if (this.searchQuery.trim() === "") return [];
      
      const options = {
        keys: [
          { name: 'references.refno', weight: 0.4 },
          { name: 'references.short', weight: 0.3 },
          { name: 'idx', weight: 0.3 },
        ],
        threshold: 0.2,
        shouldSort: true, 
        includeScore: true,
      };

      const fuse = new Fuse(this.offlineHits, options);
      const results = fuse.search(this.searchQuery);

      return results.map(result => result.item);
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },

  created() {
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightImageStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this.lightImage = lightImageStorage.get();
    this.setSelectedTier();
  },

  mounted() {
    this.loadOfflineHits();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    this.$emit('loaded');
  },

  methods: {
    ...mapMutations(['setOfflineHits', 'setValue']),
    ...mapActions(['loadOfflineHits', 'saveOfflineHits']),

    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },

    handleOfflineSearch(event) {
      if (this.searchQuery.trim() !== "") {
        this.showAutoComplete = true;
        if (event && event.key === 'Enter') {
          this.handleEnterKey(this.searchQuery);
        }
      } else {
        this.showAutoComplete = false;
      }
    },

    handleChipClick(selectedCategory) {
      this.$router.push({
        path: '/explore-cases',
        query: { category: selectedCategory }
      });
    },

    setSelectedTier() {
      if (this.userProfile && this.userProfile.role) {
        switch (this.userProfile.role) {
          case "admin":
          case "contributor":
          case "Tier4":
            this.selectedTier = "Tier4";
            break;
          case "Tier1":
            this.selectedTier = "Tier1";
            break;
          case "Tier2":
            this.selectedTier = "Tier2";
            break;
          case "Tier3":
            this.selectedTier = "Tier3";
            break;
          default:
            this.selectedTier = "Tier4";
        }
      } else {
        this.selectedTier = "Tier4"; 
      }
    },
    
    filteredHits(hits) {
      this.saveOfflineHits(hits); 
      return hits.filter(hit => hit.tiers && hit.tiers.includes(this.selectedTier));
    },

    showDate(v) {
      return moment.unix(v).format("LL");
    },

    resultPage(refValue) {
      if (this.$route.path !== "/search-result") {
        this.setValue(refValue); 
        this.$router.push("/search-result");
        this.clearTextField();
      }
    },

    handleEnterKey(search) {
      this.resultPage(search);
      this.showAutoComplete = false;
    },

    clearTextField() {
      this.currentRefinement = "";
    },

    handleCloseSearch() {
      this.showAutoComplete = false;
      this.clearTextField();
    },
  },
};
</script>

<style scoped>
.scrollable-list {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.custom-list {
  height: auto;
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}

.dark-list {
  color: #fff;
}

.light-list {
  color: #000;
}

.v-list-item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 70px;
  padding: 0px !important;
  border-radius: 25px;
}

.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-list .v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-list-item-title {
  font-weight: bold;
}

.v-list-item-subtitle {
  color: rgba(0, 0, 0, 0.6);
}

.dark-list .v-list-item-subtitle {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 600px) {
  .v-list-item-title {
    font-size: 14px;
  }

  .v-list-item-subtitle {
    font-size: 12px;
  }

  .v-list-item-action-text {
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .v-list-item-title {
    font-size: 16px;
  }

  .v-list-item-subtitle {
    font-size: 14px;
  }

  .v-list-item-action-text {
    font-size: 14px;
  }
}
</style>

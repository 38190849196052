<template>
  <v-container fluid fill-height>
    <v-card style="min-height: 100%;" elevation="0">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card-title class="text-center" style="justify-content: center;">
            <div>
              <div class="my-10 text-title hidden-md-and-down text-h1-md-and-down font-weight-bold" style="font-size: 50px">
                Welcome to TIER
              </div>
              <div class="my-4 text-title hidden-lg-and-up text-h3-lg-and-up font-weight-bold" style="font-size: 35px">
                Welcome to TIER
              </div>
              <div class="text-overline">
                {{ message }}
              </div>
            </div>
          </v-card-title>
          <v-card-text v-if="verified">
            <SearchBox @loaded="onSearchBoxLoaded" />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-overlay v-if="loading" absolute :opacity="0.9">
      <v-progress-circular
        indeterminate 
        color="primary" 
        size="64"
      ></v-progress-circular>
    </v-overlay>
    
    <TermsofServiceDialog />

    <!-- Include the consolidated Verification Dialogs component -->
    <VerificationDialog :currentUser="currentUser" :dialogType.sync="dialogType" @resend-verification-email="resendVerificationEmail" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import SearchBox from "./search/SearchBox.vue";
import VerificationDialog from "./dialogs/VerificationDialog.vue";
import moment from 'moment';
import TermsofServiceDialog from "./dialogs/TermsofServiceDialog.vue";

export default {
  data() {
    return {
      user: null,
      message: "Please verify your account.",
      verified: false,
      loading: true,
      dialogType: '',
    };
  },
  components: {
    SearchBox,
    VerificationDialog,
    TermsofServiceDialog,
  },
  computed: {
    ...mapState(["currentUser", "userProfile"]),
  },
  mounted() {
    if (this.currentUser && this.currentUser.emailVerified) {
      this.message = "You may start browsing for cases.";
      this.verified = true;
    } else {
      this.loading = false;

      const today = moment().format('YYYY-MM-DD');
      const userEmail = this.currentUser.email;
      const dialogShownInfo = JSON.parse(localStorage.getItem('dialogShownInfo')) || {};
      
      if (!this.currentUser.emailVerified && dialogShownInfo[userEmail] !== today) {
        this.dialogType = 'verify';
        dialogShownInfo[userEmail] = today;
        localStorage.setItem('dialogShownInfo', JSON.stringify(dialogShownInfo));
      }
      
      if (localStorage.getItem('emailVerificationSent') === 'true') {
        this.dialogType = 'sent';
      }
    }
  },
  methods: {
    onSearchBoxLoaded() {
      this.loading = false; 
    },
    printOut() {
      this.$store.dispatch("unsubscribe");
    },
    resendVerificationEmail() {
      // Implement the logic to resend the verification email
      // After sending the email, set the dialogType to 'sent' and update localStorage
      this.dialogType = 'sent';
      localStorage.setItem('emailVerificationSent', 'true');
    },
  },
}
</script>

<style>
.v-overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
